import React from "react";
import "./pub.scss";
const Publi = () => {
  return (
    <div className="pub">
      <h1>Coming Soon...</h1>
    </div>
  );
};

export default Publi;
