import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const Title = ({ content, image }) => {
  return (
    <div
      className="bg-cover bg-center flex justify-center before:z-0 h-[250px] before:content-[''] before:w-full before:h-full before:absolute before:bg-black before:opacity-60 relative  "
      style={{ backgroundImage: `url( ${image})` }}
    >
      <div className="z-10 flex justify-center items-center flex-col gap-2">
        <h2 className="sm:text-3xl lg:text-6xl text-white capitalize font-semibold">
          {content}
        </h2>
        <div className="back flex items-center align-middle justify-center gap-2">
          <Link
            to="/"
            className="sm:text-base lg:text-xl text-white no-underline capitalize  font-bold"
          >
            Home
          </Link>
          <FaLongArrowAltRight
            size={"1.2em"}
            className="m-0 sm:text-base lg:text-xl text-white"
          />
          <p className="sm:text-base lg:text-xl text-blue-600 m-0 capitalize font-bold">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Title;
