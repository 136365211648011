import React from "react";
import shpae1 from "../../assets/shape1.png";
import { RiStarSFill } from "react-icons/ri";
import DATASER from "../../Functions & data/ServiceData";
import { Link } from "react-router-dom";
import landingCover from "../../assets/landingCover.webp";
import whoCover from "../../assets/resizer/whoLanding.webp";
function Home() {
  return (
    <>
      <section
        className="landing bg-cover bg-no-repeat flex justify-center bg-center overflow-hidden relative py-20 z-10"
        style={{
          backgroundImage: `url(${landingCover})`,
        }}
      >
        <div className="w-full h-full bg-color5 opacity-75 z-0 absolute top-0 "></div>
        <div className="flex flex-col items-center justify-center z-10 sm:gap-3 lg:gap-5 relative align-middle h-full text-center sm:w-[85%] lg:w-[70%] ">
          <div className="">
            <h1 className="sm:text-xl md:text-4xl lg:text-5xl font-semibold text-color6">
              GBA-Think Tank
            </h1>
            <p className="sm:text-base md:text-2xl lg:text-3xl text-center text-color6 leading-9">
              Europe, Euro-Med, Arab countries, Africa, Middle East, Russia, USA
              <br />
              Districts
            </p>
          </div>
          <div>
            <p className="sm:text-xl md:text-3xl lg:text-4xl text-center text-color6">
              No challenge is too big for us
            </p>
            <p className="sm:text-[12px] md:text-lg lg:text-[22px] text-center text-color6 sm:leading-5 lg:leading-9">
              For more than thirty years, we’ve bridged cultures and connected
              continents. We champion business development, enhancing
              internationalization and integrated supply chains, empowering
              humanware get access to crystal clear focus for sustainable
              growth.
            </p>
          </div>
          <Link
            to="contact"
            className="py-2 px-3 sm:text-sm md:text-base lg:text-lg text-color5 bg-color6 rounded-md font-semibold no-underline"
          >
            Contact Us
          </Link>
        </div>
      </section>
      <section className="services my-5">
        <div className="container">
          <p className="text-5xl font-semibold text-color4">Our Services</p>
          <div className="flex flex-row flex-wrap gap-3 justify-center md:justify-evenly">
            {DATASER.map((ser) => {
              return (
                <div
                  className="sm:w-11/12 md:w-[280px] lg:w-[245px] mb-4 bg-color4 flex flex-col justify-start items-center pb-3 "
                  style={{ width: "max-width:100%" }}
                  key={ser.id}
                >
                  <img src={ser.img1} alt="" className=" max-w" />
                  <p className="text-1xl text-center bg-color5 text-color6 py-1 w-full">
                    {ser.name}
                  </p>
                  <p className="px-3 text-sm text-start line-clamp-5 h-[80%]">
                    {ser.smallDe}
                  </p>
                  <Link
                    to={`/services/${ser.SerID}`}
                    className="capitalize py-2 px-3 text-sm text-color6 bg-color5 rounded-2xl font-semibold no-underline hover:text-color5 hover:bg-color6 border-color5 border-2 transition-all ease-linear"
                  >
                    learn more
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        className="who bg-cover relative py-4 bg-no-repeat"
        id="about"
        style={{ backgroundImage: `url(${whoCover})` }}
      >
        <div className="w-full h-full bg-color5 opacity-70 z-0 absolute top-0 "></div>
        <div className="container text-color6 relative z-10">
          <div className="col-12 col-lg-6 text-start text">
            <p className="sm:text-xl lg:text-4xl font-semibold">
              Why Join GBA Think-Tank ?
            </p>
            <p className="sm:text-base w-full text-start lg:text-base leading-7">
              The best way to do business and exchange information is through
              face-to-face meetings and business networking. Backed by thirty
              years of experience in global networking, GBA-Think offers its
              members exclusive opportunities to connect with investors and
              business partners around the globe through active participation in
              high-level business activities such as Trade Delegations, Business
              Forums, Trade Fairs, Gov round table discussions, and B2B
              networking events etc. Our goal is to help our members achieve
              transformational growth and achieve their full potential whilst
              encouraging the members to be scrupulous, fair & uphold the
              highest standards of business ethics and principles.
            </p>
            <p className="sm:text-xl lg:text-2xl">Goals you will gain</p>
            <ul className="flex flex-col gap-2 pl-1 list-disc">
              <li className="w-full flex sm:text-sm flex-row items-center gap-2">
                <RiStarSFill className="w-.5/12" size={"1.3em"} />
                <p className="w-11/12 text-start m-0">
                  Gaining a new business development path for your business
                </p>
              </li>
              <li className="flex sm:text-sm flex-row  items-center gap-2">
                <RiStarSFill className="w-.5/12" size={"1.3em"} />
                <p className="w-11/12 text-start m-0">
                  Export Knowledge & Insights, new opportunities
                </p>
              </li>
              <li className="flex sm:text-sm flex-row items-center gap-2">
                <RiStarSFill className="w-.5/12" size={"1.3em"} />
                <p className="w-11/12 text-start m-0">
                  Applying internationalization models as accelerator
                </p>
              </li>
              <li className="flex sm:text-sm flex-row items-center gap-2">
                <RiStarSFill className="w-.5/12" size={"1.3em"} />
                <p className="w-11/12 text-start m-0">
                  Connecting qualified exporters to potential buyers
                </p>
              </li>
              <li className="flex sm:text-sm flex-row items-center gap-2">
                <RiStarSFill className="w-.5/12" size={"1.3em"} />
                <p className="w-11/12 text-start m-0">
                  market intelligence and new markets constantly emerging in the
                  world
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="bg-[#0f2f4c] ">
        <div className="container flex flex-row flex-wrap justify-between py-3 ">
          <div className="flex flex-row items-center relative">
            <div
              data-no="25"
              className="text-color6 text-base before:absolute sm:before:top-[28%] lg:before:top-[29%] before:left-1 sm:before:text-xl lg:before:text-3xl before:content-[attr(data-no)]"
            >
              <img src={shpae1} alt="" className="sm:w-[45px]  lg:w-[60px]" />
            </div>
            <h5 className="sm:text-sm lg:text-xl font-bold text-color6 sm:w-28 lg:w-32">
              YEARS OF EXPERIESNCES
            </h5>
          </div>
          <div className="flex flex-row items-center relative">
            <div
              data-no="500"
              className="text-color6 text-base before:absolute sm:before:top-[28%] lg:before:top-[29%] before:-left-1 lg:before:-left-2 sm:before:text-xl lg:before:text-3xl before:content-[attr(data-no)] "
            >
              <img src={shpae1} alt="" className="sm:w-[45px]   lg:w-[60px]" />
            </div>
            <h5 className="sm:text-sm lg:text-xl font-bold text-color6 sm:w-28 lg:w-32">
              MEMBERS & PARTENERS
            </h5>
          </div>
          <div className="flex flex-row items-center relative">
            <div
              data-no="20"
              className="text-color6 text-base before:absolute sm:before:top-[28%] lg:before:top-[29%] before:left-1 sm:before:text-xl lg:before:text-3xl before:content-[attr(data-no)] "
            >
              <img src={shpae1} alt="" className="sm:w-[45px]   lg:w-[60px]" />
            </div>
            <h5 className="sm:text-sm lg:text-xl font-bold text-color6 sm:w-28 lg:w-32">
              BUSINESS SECTIONS
            </h5>
          </div>
          <div className="flex flex-row items-center relative">
            <div
              data-no="98"
              className="text-color6 text-base before:absolute sm:before:top-[28%] lg:before:top-[29%] before:left-1 sm:before:text-xl lg:before:text-3xl before:content-[attr(data-no)] "
            >
              <img src={shpae1} alt="" className="sm:w-[45px]   lg:w-[60px]" />
            </div>
            <h5 className="sm:text-sm lg:text-xl font-bold text-color6 sm:w-28 lg:w-32">
              COUNTRIES
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
