import React from "react";
import icon from "../assets/mainlogo.png";
import "../sass/loading.scss";
function LoadingPage() {
  return (
    <div className="loading">
      <div className="container">
        <div className="row">
          <img src={icon} alt="" className="col-12 " />
          <h5 className="col-12">
            please wait
            <div className="dot">.</div>
            <div className="dot">.</div>
            <div className="dot">.</div>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
