import React from "react";
import DATASER from "../../Functions & data/ServiceData";
import { useParams } from "react-router";
import { BsFillCheckCircleFill } from "react-icons/bs";
const DetailSer = () => {
  const { SerID } = useParams();
  const service = DATASER.find((serv) => serv.SerID === SerID);
  const {
    fname,
    img1,
    img2,
    details,
    title,
    moreTile,
    list1,
    list2,
    list3,
    list4,
    list5,
    list6,
    list7,
  } = service;
  return (
    <div>
      <h1 className=" sm:text-xl md:text-2xl lg:text-4xl font-bold">{fname}</h1>
      <h4 className="sm:text-lg md:text-xl lg:text-2xl font-semibold">
        {title}
      </h4>
      <p className="sm:text-sm md:text-base lg:text-base ">{details}</p>
      <h6 className="sm:text-base md:text-lg lg:text-lg font-semibold">
        {moreTile}
      </h6>
      <ul className="pl-1 ">
        {list1 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
            
              {list1}
            </p>
          </li>
        ) : null}
        {list2 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base ">
            
              {list2}
            </p>
          </li>
        ) : null}
        {list3 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
              {list3}
            </p>
          </li>
        ) : null}
        {list4 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
            
              {list4}
            </p>
          </li>
        ) : null}
        {list5 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
            
              {list5}
            </p>
          </li>
        ) : null}
        {list6 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
            
              {list6}
            </p>
          </li>
        ) : null}
        {list7 ? (
          <li className="flex items-center mb-2">
            <BsFillCheckCircleFill className="sm:text-sm sm:w-[10%] md:w-[6%] lg:w-[4%] text-blue-900 " />
            <p className="m-0 text-gray-800 sm:w-[90%] md:w-[94%] lg:w-[96%] sm:text-xs md:text-base">
            
              {list7}
            </p>
          </li>
        ) : null}
      </ul>
      <div className="flex flex-row gap-4">
        <img
          src={img1}
          alt=""
          className="sm:w-6/12 md:w-5/12 lg:w-4/12 rounded-md border-2 border-gray-300 p-1"
        />
        <img
          src={img2}
          alt=""
          className="sm:w-6/12 md:w-5/12 lg:w-4/12 rounded-md border-2 border-gray-300 p-1"
        />
      </div>
    </div>
  );
};

export default DetailSer;
