import Home from "./views/home/home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./views/About/About";
import Publi from "./views/publication/pub";
import Services from "./views/services/services";
import Footer from "./component/footer/foot";
import NavBar from "./component/nav/nav";
import Contact from "./views/contact/contact";
import Login from "./views/Member-login/Login";
import ScrollToTop from "./Functions & data/scrollToTop";
import { useState, useEffect } from "react";
import LoadingPage from "./Functions & data/LoadingPage";
import Error from "./component/Error/Error";
import DetailSer from "./views/services/DetailSer";
function App() {
  const [isloading, setIsLoading] = useState(false);
  const [servDropDown, setServDropDown] = useState("hidden");
  const [nav, setNav] = useState("hidden");

  const MenuBar = (e) => {
    e.stopPropagation();
    if (
      e.target.id !== "navBar" &&
      e.target.id !== "dropdown-basic" &&
      e.target.id !== "navContainer" &&
      e.target.id !== "dropdown-basic" &&
      e.target.id !== "navItems" &&
      e.target.id !== "navLogo"
    ) {
      setNav("hidden");
      setServDropDown("hidden");
    }
    console.log(e.target.id);
  };
  useEffect((e) => {
    document.addEventListener("click", MenuBar);
  });
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  console.log(nav);
  return (
    <Router>
      {isloading ? (
        <LoadingPage />
      ) : (
        <>
          <NavBar
            setServDropDown={setServDropDown}
            servDropDown={servDropDown}
            nav={nav}
            setNav={setNav}
          />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="publications" element={<Publi />} />
            <Route path="services" element={<Services />}>
              <Route path=":SerID" element={<DetailSer />} />
            </Route>
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
