import React from "react";
import { ImPhone } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { HiArrowLongRight } from "react-icons/hi2";
import Title from "../../component/title/title";
import contactImage from "../../assets/resizer/contact.webp";

const Contact = () => {
  return (
    <>
      <Title content="contact" image={contactImage} />
      <section className="contact container justify-center flex flex-wrap">
        <div className="w-full mt-6 mb-3 flex flex-col items-center ">
          <h4 className="sm:text-1xl lg:text-3xl font-semibold">
            GET IN TOUCH WITH US
          </h4>
          <p className="sm:text-sm lg:text-lg sm:w-full lg:w-[40%] text-center">
            We are here to help and answer any question you might have. We look
            forward to hearing from you
          </p>
        </div>
        <div className="w-11/12 flex sm:flex-col-reverse lg:flex-row mt-4 mb-9 justify-between">
          <div className="message flex flex-col sm:w-full lg:w-8/12 p-4 bg-slate-100">
            <form action="" className="flex flex-col justify-center py-6 px-2">
              <p className="sm:text-xl lg:text-2xl text-color1 mb-[40px] font-bold capitalize">
                send your subject
              </p>
              <div className="flex w-full sm:flex-col lg:flex-row flex-wrap gap-3 justify-between">
                <div className="name relative sm:w-full lg:w-[46%]">
                  <input
                    type="text"
                    name="name"
                    className="w-full relative border-b-2 border-color1 bg-slate-100 pt-2 pb-1 sm:text-sm lg:text-base outline-none capitalize "
                    placeholder="enter your name"
                    required
                  />
                </div>
                <div className="email relative sm:w-full lg:w-[46%]">
                  <input
                    type="email"
                    name="email"
                    className="w-full relative  border-b-2 border-color1 bg-slate-100 pt-2 pb-1 sm:text-sm lg:text-base outline-none capitalize "
                    placeholder="enter your email"
                    required
                  />
                </div>
                <div className="message w-full relative mt-4">
                  <textarea
                    name="message"
                    className="sm:max-h-[120px] lg:max-h-[230px] min-h-[190px] w-[100%] relative bg-slate-100 border-2 border-color1 pt-2 pl-2 sm:text-sm lg:text-base outline-none capitalize "
                    placeholder="your message"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="items-center align-middle gap-2 sm:text-base lg:text-xl uppercase border-2 border-color1 text-color6 bg-color1 w-fit flex self-center my-4 py-1 px-4 rounded-xl hover:bg-color6 hover:text-color1 ease-linear transition"
              >
                send <HiArrowLongRight className="text-2xl" />
              </button>
            </form>
          </div>
          <div className="information flex flex-col sm:w-full lg:w-4/12 bg-color1 text-color6 py-10 px-4 justify-evenly">
            <p className="text-2xl font-bold capitalize ">
              contact information
            </p>
            <div className="w-full flex gap-1 my-3 justify-between border-b-2 border-color6 pb-4">
              <p className="flex sm:text-base lg:text-xl gap-2 items-center font-bold">
                Email <HiOutlineMail />
              </p>
              <div className="content items-center flex flex-col ">
                <p className="m-0"> info@gbathink.org </p>
                <p className="m-0">farag@gbathink.org</p>
              </div>
            </div>
            <div className="w-full flex gap-1 my-3 justify-between border-b-2 border-color6 pb-4">
              <p className="flex sm:text-base lg:text-xl gap-2 items-center font-bold">
                Phone
                <ImPhone />
              </p>
              <div className="content items-center flex flex-col ">
                <p className="m-0"> +2 0100 306 4112 </p>
                <p className="m-0"> +30 69 44 777 452</p>
              </div>
            </div>
            <div className="w-full flex gap-1 my-3 justify-between border-b-2 border-color6 pb-4">
              <div className="content items-center flex ">
                <p className="text-base m-0">
                  Europe, Euro-Med, Arab countries, Africa, Middle East, Russia,
                  USA
                  <br />
                  Districts
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
