import logo from "../../assets/mainlogo.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { GiDividedSquare } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";
function NavBar({ servDropDown, setServDropDown, nav, setNav }) {
  const DropDown = () => {
    if (servDropDown === "hidden") {
      setServDropDown("block");
    } else {
      setServDropDown("hidden");
    }
  };
  const menuBar = (e) => {
    e.stopPropagation();
    if (nav === "hidden") {
      setNav("flex");
    } else if (nav === "flex") {
      setNav("hidden");
    }
  };
  return (
    <nav id="navBar" className="bg-color5  py-2  z-[101] sticky top-0">
      <div
        id="navContainer"
        className="container sm:flex sm:flex-col lg:flex lg:flex-row sm:py-2"
      >
        <div
          id="navLogo"
          className="flex flex-row items-center brand sm:w-full md:w-full lg:w-2/12 justify-between"
        >
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className=" sm:w-[85px] md:w-[150px] lg:w-[160px]"
            />
          </Link>
          <div className=" lg:hidden sm:flex justify-end">
            <FaBars
              id="menuBar"
              aria-controls="basic-navbar-nav"
              className="menuBar sm:text-2xl md:text-4xl text-color1 "
              onClick={menuBar}
            />
          </div>
        </div>
        <div
          id="basic-navbar-nav"
          className={`${nav} sm:w-full sm:gap-5 sm:flex-col lg:w-10/12 lg:flex-row lg:flex items-center `}
        >
          <div
            id="navItems"
            className="flex items-center sm:w-full lg:w-9/12 gap-3 sm:justify-center sm:flex-col  lg:flex-row"
          >
            <Link
              to="/"
              className="sm:text-base lg:text-lg text-white no-underline relative before:content-[''] before:bottom-0 before:absolute before:bg-color6 before:w-0 before:h-[1.5px] hover:before:w-full "
            >
              Home
            </Link>
            <Link
              to="/about"
              className="sm:text-base lg:text-lg text-white no-underline relative before:content-[''] before:bottom-0 before:absolute before:bg-color6 before:w-0 before:h-[1.5px] hover:before:w-full "
            >
              About
            </Link>
            <button
              id="dropdown-basic"
              className=" sm:text-base lg:text-lg text-white flex items-center"
              onClick={DropDown}
            >
              Services
              <IoMdArrowDropdown />
            </button>
            <div
              className={`flex sm:flex-col sm:py-2 lg:flex-row lg:absolute bg-color6 sm:justify-start lg:justify-between sm:gap-1 lg:gap-5 rounded-md top-[70px] z-50 sm:w-fit sm:h-52 sm:overflow-scroll lg:overflow-hidden lg:w-[600px] ${servDropDown}`}
            >
              <div className="flex flex-col gap-2 lg:py-3 px-3  lg:w-full ">
                <Link
                  to="/services/business-delegations"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline  "
                >
                  <GiDividedSquare className="text-color1" />
                  business delegations
                </Link>
                <Link
                  to="/services/diplomatic-liaison"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  diplomatic liaison
                </Link>
                <Link
                  to="/services/trade-commercial"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  trade commercial
                </Link>
                <Link
                  to="/services/global-reach"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  global reach
                </Link>
                <Link
                  to="/services/international-tenders"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  international tenders
                </Link>
              </div>
              <span className="h-44 self-center sm:invisible lg:visible w-[1px] bg-black flex"></span>
              <div className="flex flex-col gap-2  lg:py-3 px-3 w-full ">
                <Link
                  to="/services/trade-desk"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  trade desk
                </Link>
                <Link
                  to="/services/academia-desk"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  academia desk
                </Link>
                <Link
                  to="/services/networking"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  networking
                </Link>
                <Link
                  to="/services/business-development"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  business development
                </Link>
                <Link
                  to="/services/internationalization-services"
                  className="flex gap-1 items-center text-color3 w-full capitalize sm:text-sm lg:text-base hover:bg-color1 hover:text-color4 hover:transition-all no-underline"
                >
                  <GiDividedSquare className="text-color1" />
                  internationalization services
                </Link>
              </div>
            </div>
            <Link
              to="/publications"
              className="sm:text-base lg:text-lg text-white no-underline relative before:content-[''] before:bottom-0 before:absolute before:bg-color6 before:w-0 before:h-[1.5px] hover:before:w-full "
            >
              Publications
            </Link>
          </div>
          <div
            id="navItems"
            className="flex flex-row sm:w-full sm:justify-center items-center lg:justify-end lg:w-3/12 gap-3 "
          >
            <Link
              to="/contact"
              className=" sm:text-base lg:text-lg text-center text-color4 no-underline border-1 border-white px-2 py-1 rounded-md hover:bg-color6 hover:text-color3 w-5/6 "
            >
              Contact
            </Link>
            <Link
              to="/login"
              className=" sm:text-base lg:text-lg text-center text-color4 no-underline border-1 border-white px-2 py-1 rounded-md hover:bg-color6 hover:text-color3 w-5/6 "
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
