import bd1 from "../assets/serWeb/1.webp";
import bd2 from "../assets/serWeb/2.webp";
import dl from "../assets/serWeb/2Di.webp";
import net1 from "../assets/serWeb/net1 (1).webp";
import net2 from "../assets/serWeb/net1 (2).webp";
import gl1 from "../assets/serWeb/global (1).webp";
import gl2 from "../assets/serWeb/global (2).webp";
import it1 from "../assets/serWeb/intert (1).webp";
import it2 from "../assets/serWeb/intert (2).webp";
import td1 from "../assets/serWeb/tradede (1).webp";
import td2 from "../assets/serWeb/tradede (2).webp";
import aca1 from "../assets/serWeb/aca (1).webp";
import aca2 from "../assets/serWeb/aca (2).webp";
import bdm1 from "../assets/serWeb/bussdev1.webp"; 
import bdm2 from "../assets/serWeb/bussdev2.webp";
import is1 from "../assets/serWeb/inters (1).webp";
import is2 from "../assets/serWeb/inters (2).webp";
import traco1 from "../assets/serWeb/traco1.webp";
import traco2 from "../assets/serWeb/traco2.webp";
const DATASER = [
  {
    SerID: "business-delegations",
    fname: "Business Delegations",
    name: "Business Delegations",
    smallDe:
      "International business delegations are an effective approach to create a frame that nurtures international trade....",
    details:
      "International business delegations are an effective approach to create a frame that nurtures international trade and business relations and provides first-hand information for new markets, products, investment projects, and business partners. Furthermore, business delegations provide an opportunity to share knowledge and experience with local professionals and assess the local market conditions before taking an action. (GBA Think-Tank) arranges international delegations and brings you in direct contact with the latest business development opportunities, products, and services from emerging markets. We create a common meeting ground for companies bringing sellers in contact with buyers and business partners to promote trade, commerce, and international cooperation between national and foreign companies.",
    img1: bd1,
    img2: bd2,
    nameHome: "BussDel",
  },
  {
    SerID: "diplomatic-liaison",
    fname: "Diplomatic Liaison",
    name: "Diplomatic Liaison",
    smallDe:
      "(GBA Think-Tank) promotes international understanding and networking through educational, informational economic, and cultural....",
    details:
      "(GBA Think-Tank) promotes international understanding and networking through educational, informational economic, and cultural exchanges with members of the diplomatic community.",
    img1: dl,
    img2: "",
    nameHome: "DipLi",
  },
  {
    SerID: "trade-commercial",
    name: "Trade & Commercial ",
    fname: "Trade & Commercial Representatives",
    smallDe:
      "When expanding to targeted market it is important to understand the local commercial and trade legislation... ",
    moreTile:
      "Taking advantage of commercial representation services with us will ensure that your company reaches its business objectives when entering the targeted market.  Experienced commercial representatives can enable businesses to succeed by:",
    list1:
      "Understanding your business needs: This means understanding what market your company is aiming to break into and what are the business objectives and aims.",
    list2:
      "Conducting market validation: In order to validate the market, a detailed analysis needs to be carried to see what factors can be implemented to achieve successful market penetration.",
    list3:
      "Performing a route-to-market review: The commercial representative  can help you and your company develop a tailored brand positioning strategy and identify a route-to-market.",
    list4:
      "Conduct commercial engagement: A commercial representative can also support business expansion by coordinating all formal contact and negotiations with interested parties on behalf of the client.",
    img1: traco1,
    img2: traco2,
    nameHome: "TraCo",
  },
  {
    SerID: "networking",
    fname: "Networking",
    name: "Networking",
    smallDe:
      "We provide networking services that will set your business up for success. We'll provide you with....",
    details:
      "We provide networking services that will set your business up for success. We'll provide you with the network monitoring you need to keep things running properly. The framework you require to boost and enhance production will be provided by us. We offer the responsive service you require when issues arise",
    img1: net1,
    img2: net2,
    nameHome: "Netw",
  },
  {
    SerID: "global-reach",
    name: "Global Reach JV",
    fname: "Global Reach JV Partnerships",
    smallDe:
      "What Is a Joint Venture? Global joint ventures can be an excellent business strategic alliance....",
    title: "What Is a Joint Venture?",
    details:
      "Global joint ventures can be an excellent business strategic alliance . They give businesses access to international markets, technologies, and resources. Companies aiming to expand their product lines into new areas may form joint ventures with partners who can make up for their limitations with complementing strengths.",
    moreTile:
      "Advantages of Joint Ventures By teaming up with other people or businesses in a joint venture, you can:",
    list1: "Extend your marketing reach.",
    list2: "Gain access to necessary data, tools, and skill sets.",
    list3: "Build credibility with a particular target market.",
    list4: "Reach new markets that would be inaccessible without the partner.",
    list5:
      "Gain access to intellectual property that would otherwise be out of your reach.",
    list6: "Discover new revenue streams.",
    list7: "Share risks and costs.",
    img1: gl2,
    img2: gl1,
    nameHome: "Glob",
  },
  {
    SerID: "international-tenders",
    fname: "International Tenders",
    name: "International Tenders",
    smallDe:
      "International Tenders is one of the largest sources for Tenders and Business Intelligence....",
    details:
      "International Tenders is one of the largest sources for Tenders and Business Intelligence. Aimed at making information easier to all members to access international tenders’ opportunities More than 250 notices are uploaded daily. The information gathered is categorized by a team of researchers who classify it by date, country, sectors and keywords, presenting businesses with refined actionable data. With an elaborate list of services evolved with new business opportunities. All registered members have access to the dashboard. The dashboard shall offer you a tailored look and you shall see recommended notices on the dashboard without having to search every time you log in.",
    img1: it1,
    img2: it2,
    nameHome: "InterTen",
  },

  {
    SerID: "trade-desk",
    fname: "Trade Desk – Studies",
    name: "Trade Desk – Studies",
    smallDe:
      "The academia desk seeks to provide business-relevant academic consultancy and support services that foster ....",
    details:
      "A trade desk is filled with traders, proprietors, and brokers who act as agents matching buyers and sellers, where you can identify and evaluate new trade opportunities across target markets or find partners for financing, trade strategy development, distribution, and more. (GBA Think-Tank’s) Trade Desk helps you discover a world of trade opportunities in one place with detailed information about imports, exports, market dynamics, tariffs, regulatory requirements, potential buyers & sellers, and prepare your business to expand into new markets. Our trade desk enables you to identify and evaluate new trade opportunities across target markets or find partners for financing, trade strategy development, distribution, and more.",
    img1: td1,
    img2: td2,
    nameHome: "TradStu",
  },
  {
    SerID: "academia-desk",
    fname: "Acadeima Desk",
    name: "Acadeima Desk",
    smallDe:
      "The academia desk seeks to provide business-relevant academic consultancy....",
    details:
      "The academia desk seeks to provide business-relevant academic consultancy and support services that foster academic success and lead students to readiness for career integration and/or advanced degree aspiration. (GBA Think-Tank’s) Academic Desk helps local universities to connect with their foreign counterparts and industry partners across the world. It helps academia in devising and running international business courses and research initiatives that are closely associated with the current market problems and needs. It creates opportunities for the cooperation and collaboration of academia and industry for economic growth and sustainability.",
    img1: aca1,
    img2: aca2,
    nameHome: "AcaDes",
  },
  {
    SerID: "business-development",
    fname: "Business Development Models",
    name: "Business Development",
    smallDe:
      "A business plan is essential for a new business. The business development model is a detailed plan that....",
    details:
      "A business plan is essential for a new business. The business development model is a detailed plan that defines how company resources will be used to create sales and revenue. This model is a guide for the strategy and growth of a business. The model will change over time based on new market trends and business demand. The business model is a plan for running a company. It is important to build this plan based on strategies known to work. Strategic business development is the alignment of business development processes and procedures with your firm’s strategic business goals. The role of strategic business development is to acquire ideal clients for your highest priority services using brand promises that you can deliver upon. Deciding which targets to pursue and strategies to employ to develop new business is actually a high stakes decision. A good strategy, well implemented, can drive high levels of growth and profitability. A faulty strategy can stymie growth and frustrate valuable talent.",
    img2: bdm1,
    img1: bdm2,
    nameHome: "BussDev",
  },
  {
    SerID: "internationalization-services",
    fname: "Internationalization Services",
    name: "Internationalization Services",
    smallDe:
      "The Internationalization of services is at the very core of economic globalization. Service industries provide....",
    details:
      "The Internationalization of services is at the very core of economic globalization. Service industries provide ids between geographically dispersed economic activities and thus play a fundamental role in the growing interdependence of markets and production activities across nations.",
    img1: is1,
    img2: is2,
    nameHome: "IntSer",
  },
];

export default DATASER;
