import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import DATASER from "../../Functions & data/ServiceData";
import Title from "../../component/title/title";
import contactImage from "../../assets/serWeb/2Di.webp";
// Start Services Page
const Services = () => {
  // Data services
  const ServiceLink = DATASER.map((e) => {
    return (
      <Link
        to={e.SerID}
        key={e.SerID}
        className={`text-base text-color3 p-2 bg-color4 no-underline hover:bg-color1 hover:text-white`}
      >
        {e.fname}
      </Link>
    );
  });
  //////////////////////////////////////
  return (
    <>
      <Title content="services" image={contactImage} />
      <section className="service m-4 ">
        <div className="container flex gap-5 flex-row flex-wrap">
          <div className="sm:w-full lg:w-3/12 flex flex-col gap-3">
            {ServiceLink}
          </div>
          <div className="sm:w-full lg:w-8/12 ">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
