import React from "react";
import { Link } from "react-router-dom";
import "./error.scss";
function Error() {
  return (
    <div className="error">
      <div className="container">
        <h1>404</h1>
        <p>page not found</p>
        <Link to="/">back home</Link>
      </div>
    </div>
  );
}

export default Error;
