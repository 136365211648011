import React from "react";
import { useForm } from "react-hook-form";
import "./login.scss";
import logoImage from "../../assets/mainlogo.png";
import { BsFillPersonFill, BsCheckCircleFill } from "react-icons/bs";
import { RiLockPasswordLine, RiCloseCircleFill } from "react-icons/ri";
export default function Login() {
  const { need, handleSubmit } = useForm();

  const OnLogin = (data) => {
    console.log(data);
  };
  return (
    <>
      <div className="sign-up">
        <div className="col-12 col-md-6 logo">
          <img src={logoImage} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <form action="#" onSubmit={handleSubmit(OnLogin)}>
                <div className="div">
                  <BsFillPersonFill />
                  <input
                    type="text"
                    required
                    placeholder="Username"
                    ref={need}
                  />
                  <div>
                    <BsCheckCircleFill className="check" />
                    <RiCloseCircleFill className="wrong" />
                  </div>
                </div>
                <div>
                  <RiLockPasswordLine />
                  <input
                    type="password"
                    required
                    placeholder="Password"
                    ref={need}
                  />
                  <div>
                    <BsCheckCircleFill className="check" />
                    <RiCloseCircleFill className="wrong" />
                  </div>
                </div>

                <button type="submit">Sign in</button>
                <a href="/">Forget your password ?</a>
                <a href="/">Be a member</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
