import React from "react";
import logo from "../../assets/mainlogo2.png";
import {
  FaFacebook,
  FaInstagram,
  FaTwitterSquare,
  FaLinkedin,
  FaRegCopyright,
} from "react-icons/fa";
import { ImLocation2, ImPhone } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import footerCover from "../../assets/resizer/contact.webp";
const Footer = () => {
  return (
    <footer id="footer">
      <div
        className="bg-cover sm:py-2 lg:py-10 relative grayscale"
        style={{ backgroundImage: `url(${footerCover})` }}
      >
        <div className="container flex flex-row justify-between items-center">
          <p className="w-6/12 sm:text-sm lg:text-3xl text-color6 ">
            If you have any query ... We are Available
          </p>
          <Link
            to="/contact"
            className=" py-2 px-3 sm:text-sm md:text-base relative z-10 lg:text-xl capitalize text-color5 bg-color6 grayscale-0 rounded-md font-semibold no-underline"
          >
            contact Now
          </Link>
        </div>
      </div>
      <div className="flex container items-start py-4 gap-5 sm:flex-wrap sm:justify-center lg:justify-between flex-row">
        <div className="flex flex-col sm:w-11/12 sm:items-center xl:w-2/12 gap-6 xl:items-start">
          <img src={logo} alt="" className="sm:w-[150px] lg:w-[200px]" />
          <ul className="flex flex-row gap-3 lg:items-start sm:justify-center p-0 m-0 w-full">
            <li className=" sm:text-xl lg:text-2xl">
              <FaFacebook />
            </li>
            <li className=" sm:text-xl lg:text-2xl ">
              <FaInstagram />
            </li>
            <li className=" sm:text-xl lg:text-2xl ">
              <FaTwitterSquare />
            </li>
            <li className=" sm:text-xl lg:text-2xl ">
              <FaLinkedin />
            </li>
          </ul>
        </div>
        <div className="flex flex-col sm:w-5/12 lg:w-2/12 xl:w-2/12 sm:items-center xl:items-start">
          <h4 className="sm:text-xl  lg:text-2xl border-b-2 border-back ">
            Links
          </h4>
          <ul className="flex flex-col sm:items-center xl:items-start sm:w-full gap-3 p-0">
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link to="/" className="no-underline text-lg text-black">
                Home
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link to="/about" className="no-underline text-lg text-black">
                About Us
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link
                to="/publications"
                className="no-underline text-lg text-black"
              >
                Publications
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link to="/contact" className="no-underline text-lg text-black">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col sm:w-5/12 lg:w-2/12  xl:w-2/12 sm:items-center xl:items-start">
          <h4 className="sm:text-xl  lg:text-2xl border-b-2 border-back ">
            Services
          </h4>
          <ul className="flex flex-col sm:items-center xl:items-start sm:w-full gap-3 p-0">
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link
                to="/services/business-delegations"
                className="no-underline sm:text-base sm:text-center flex lg:text-lg text-black "
              >
                Business Delegations
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link
                to="/services/diplomatic-liaison"
                className="no-underline sm:text-base sm:text-center flex lg:text-lg text-black"
              >
                Diplomatic Liaison
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link
                to="/services/trade-desk"
                className="no-underline sm:text-base sm:text-center flex lg:text-lg  text-black"
              >
                Trade Desk
              </Link>
            </li>
            <li className="border-b-2 border-color6 hover:border-b-2 hover:translate-x-2 ease-in-out transition-all text-black">
              <Link
                to="/services/networking"
                className="no-underline sm:text-base sm:text-center flex lg:text-lg  text-black"
              >
                Networking
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col sm:w-11/12 lg:w-5/12  xl:w-4/12 sm:items-center lg:items-start ">
          <p className="sm:text-xl sm:text-center lg:text-left sm:w-fit lg:text-2xl border-b-2 border-back">
            Contact Us
          </p>
          <div className=" my-2 flex sm:items-center lg:w-full gap-2">
            <ImLocation2 className="sm:text-base lg:text-xl" />
            <p className="sm:text-base lg:text-xl m-0">
              Middle east, Africa, Arab regions
            </p>
          </div>
          <div className="content w-fit sm:text-center lg:text-start lg:w-full">
            <h2 className="sm:text-sm lg:text-base font-bold ">
              Partnerships and Development Cooperation <br />
              Department:
            </h2>
            <p className="flex flex-row items-center sm:justify-center lg:justify-start gap-1 my-1">
              <ImPhone />
              Tel: +2 0100 306 4112
            </p>
            <p className="flex flex-row items-center sm:justify-center lg:justify-start gap-1">
              <HiOutlineMail />
              Email:farag@gbathink.org
            </p>
            <h2 className="sm:text-sm lg:text-base font-bold mt-3">
              Help desk for Business on International <br />
              standards:
            </h2>
            <p className="flex flex-row items-center sm:justify-center lg:justify-start gap-1 my-1">
              <ImPhone />
              Tel: +30 69 44 777 452
            </p>
            <p className="flex flex-row items-center sm:justify-center lg:justify-start gap-1">
              <HiOutlineMail />
              Email: info@gbathink.org
            </p>
          </div>
        </div>
      </div>
      <div className="copyright py-3 items-center  flex justify-center bg-color5 text-color6">
        <p className="text-lg flex flex-row gap-2 items-center align-middle m-0">
          GBA Think-Tank copyright
          <FaRegCopyright /> 2022
        </p>
      </div>
    </footer>
  );
};

export default Footer;
