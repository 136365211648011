import React, { useState } from "react";
// import "./about.scss";
import aboutI from "../../assets/about1.jpg";
import about2 from "../../assets/about2.jpg";
import about3 from "../../assets/about3.jpg";
import Title from "../../component/title/title";
import { Button, Collapse } from "@material-ui/core";
import contactImage from "../../assets/resizer/about2.webp";
import pattern from "../../assets/pattern-bg.jpg";
const About = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Title content="about" image={contactImage} />
      <div className=" mt-5 flex gap-10 flex-col">
        <div className="flex gap-5 sm:flex-col lg:flex-row container who">
          <div className="sm:w-full h-full lg:w-5/12">
            <img src={aboutI} alt="" className="rounded-lg" />
          </div>
          <div className="flex flex-col lg:w-6/12 sm:mb-3 lg:mb-10">
            <h1 className="sm:text-2xl lg:text-4xl font-semibold">
              Who We Are
            </h1>
            <p className="sm:text-sm lg:text-base ">
              Global Business Alliance – Think-Tank (GBA Think-Tank) : is an
              international business and trade cooperation networking
              think-tank, using face to face and platforms to connect companies
              across the globe. (GBA Think-Tank) is offering reliable business
              partners and services in multiple countries through our strong
              network connections with international organizations,
              international development bodies, funding entities, diplomatic
              missions, governments, and trade bodies & businesses in different
              countries.
            </p>
            <p className="sm:text-sm lg:text-base ">
              (GBA Think-Tank) is a place where foreign corporations, trade and
              business policy makers from different countries can exchange
              business ideas and policies together. As the premier voice of
              international companies in different districts and regions, (GBA
              Think-Tank) actively promotes and defends an open economy that
              welcomes international companies to invest in all joint districts.
              Our members are European, Euro-Med, Arab, African, and Middle East
              companies with global heritage and an indispensable part of
              nation’s economic success.
            </p>
            <p className="sm:text-sm lg:text-base ">
              We offer a competitive advantage to members to avail the
              opportunities for result-oriented growth, partnership, and
              strategic alliance with global partners from different countries.
              (GBA Think-Tank), backed by thirty years of experience in
              networking is aimed at offering strategic business solutions and
              information for improving trade, economic cooperation,
              strengthening trade base, and improving exports and
              internationalization among countries. Driven through technology
              and backed by years of solid networks,
            </p>
            <Button
              className="text-lg w-fit"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Read More
            </Button>
            <Collapse in={open}>
              <div id="example-collapse-text">
                <p className="sm:text-sm lg:text-base ">
                  We are promoting exports and bilateral trade through strong
                  business awareness without spending much extra time searching
                  or experiencing any financial impact. GBA offers its members
                  exclusive opportunities to share their company profiles and
                  strengths with global investors and business partners and be
                  part of business delegations to engage in high-level business
                  activities. We are open to membership to form a coalition and
                  support a better and prosperous world.
                </p>
                <p className="sm:text-sm lg:text-base ">
                  We are empowering our members and strengthening their economy
                  by providing them with business development paths-evidence
                  base with strong back ground experiences, original research,
                  FDI reports, issue primers, industry factsheets and shareable
                  content.
                </p>
                <p className="sm:text-sm lg:text-base ">
                  (GBA Think-Tank) aimed to connect by exploring the
                  possibilities of promoting and exploring bilateral and
                  multilateral trade, building a lasting alliance for new
                  markets, and capitalizing on existing business by sharing
                  business information and opportunities profitable to the
                  members of this organization.
                </p>
              </div>
            </Collapse>
          </div>
        </div>
        <div
          className="bg-cover bg-center"
          style={{ backgroundImage: `url(${pattern})` }}
        >
          <div className="lg:pl-24 flex flex-row  w-full sm:justify-center lg:justify-between gap-4">
            <div className="container lg:px-0 sm:w-full lg:w-5/12 sm:mb-3 lg:mb-10 py-8 text-white sm:text-center lg:text-start">
              <h3 className="sm:text-2xl lg:text-4xl font-semibold">
                Consulting Global Alliance Partners
              </h3>
              <p className="sm:text-sm lg:text-base ">
                We and merged, connected with international Consulting entities,
                our offices in Africa, Meddle east, Asia, Europe, North America
                and South America form alliances with our partners. This enables
                us to provide close-fitting collaborative services, and smooth,
                prompt support for the global business operations of customers.
                In the finance, manufacturing, public-sector, telecommunications
                and energy fields, we provide uniform support across the full
                range from strategy proposals that support our clients in their
                transformation efforts to systems development, integration and
                operational management in different sectors and fields. This is
                how we contribute to the construction of our clients’ global
                business infrastructure.
              </p>
            </div>
            <div className=" sm:hidden lg:block lg:w-8/12 ">
              <img
                src={about2}
                alt=""
                className="rounded-md w-full h-full sm:hidden lg:block"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-5 sm:flex-col lg:flex-row container mission ">
          <div className="sm:w-full lg:w-6/12">
            <img src={about3} alt="" className="rounded-lg" />
          </div>
          <div className="flex flex-col lg:w-6/12 sm:mb-3 lg:mb-10">
            <h3 className="sm:text-2xl lg:text-4xl font-semibold">
              Mission & Vision
            </h3>
            <p className="sm:text-sm lg:text-base">
              Profound changes in power, authority, governance,
              internationalization and technology are taking place within and
              between countries, eroding trust and stability. These changes are
              demanding a fundamental redirection of global and national rules,
              systems, and markets to deliver global goods and public services.
              GBA-Think’s mission is to help members build secure and prosperous
              pathways to sustainable growth that protect the planet and reduce
              inequalities within and between countries.
            </p>
            <p className="sm:text-sm lg:text-base">
              We pursue our mission through dialogue, analysis, and
              solutions-based ideas, empowering the next generation to build a
              better economic world. We ensure our research and outputs are
              widely accessible, so people can develop their voice in
              international affairs. Collaboration is a core competence for our
              staff. It inspires our relationships with associates, partners,
              supporters, governments, and members and helps us develop global
              networks to find positive, durable solutions. We are examining new
              forms of international cooperation, engaging diverse global
              actors, and exploring different tools and technologies to deliver
              our mission in this turbulent century.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
